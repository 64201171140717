import { ImCancelCircle } from "react-icons/im";
import React, { useState } from "react";
import { AUTH_TOKEN, BASE_URL } from "../../../utils";
import axios from "axios";
import Swal from "sweetalert2";

export const SkipButton = ({
  allPatient,
  setIsRecallToken,
  setIsRecallModel,
  setTokenType,
  isRecallToken,
  tokenType,
  shift_id,
  title
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSkipButtonClick = async () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    try {
      setIsLoading(true);
      const reqData = {
        token_number: isRecallToken,
        status: tokenType,
        shift_id: shift_id,
      };
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/skip`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
        setIsRecallToken(null);
        setIsRecallModel(false);
        setTokenType("panding");
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`btn btn-warning ${title === "Skip" ? "mt-2 px-4" : ""}`}
      style={{ width: "160px" }}
      type="button"
      onClick={handleSkipButtonClick}
      disabled={isLoading}
    >
      <span className="" onClick={() => setIsRecallToken(null)}>
        {isLoading && (
          <span className="spinner-border spinner-border-sm mr-1"></span>
        )}
        <ImCancelCircle /> {title}
      </span>
    </button>
  );
};
