import React, { useState, useEffect } from "react";
import { Dashboard } from "./Dashboard";
import { MdOutlineNotes } from "react-icons/md";
import {
  AUTH_TOKEN,
  USER_DETAILS,
  BASE_URL,
  TOKEN_KEY,
  AUTH_KEY,
} from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import ShiftComponent from "./components/ShiftComponent";
import Header from "./Header";
import { RecallModel } from "../../models/RecallModel";
import { PatientInModel } from "../../models/PatientInModel";
import { SkipRecallModel } from "../../models/SkipRecallModel";
import { BreakModel } from "../../models/BreakModel";
import { StartModel } from "../../models/StartModel";
import { EmergencyModel } from "../../models/EmergencyModel";
import { DescriptionModel } from "../../models/DescriptionModel";
import { PrintModel } from "../../models/PrintModel";
import { useNavigate } from "react-router-dom";
import Head from "./components/Head";
import TokenListDash from "./components/TokenListDash";
import SkipPatientDash from "./components/SkipPatientDash";
import { DashboardTotal } from "./components/DashboardTotal";
import { NextButton } from "./Buttons/NextButton";
import { SkipButton } from "./Buttons/SkipButton";
import RecallButton from "./Buttons/RecallButton";
import DashboardBreak from "./components/DashboardBreak";
import { DonePatientList } from "./components/DonePatientList";
import { StartStop } from "./Buttons/StartStop";
import { PatientList } from "./components/PatientList";
import { DescList } from "./components/DescList";
import { ViewToken } from "./components/ViewToken";
//import Echo from "laravel-echo";
//import Pusher from "pusher-js";

export const DashboardData = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [descriptions, setDescription] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [isModel, setIsModel] = useState(false);
  const [isEmergencyModel, setIsEmergencyModel] = useState(false);
  const [isSkipRecallModel, setIsSkipRecallModel] = useState(false);
  const [isRecallModel, setIsRecallModel] = useState(false);
  const [patientInModel, setPatientInModel] = useState(false);
  const [isRecallToken, setIsRecallToken] = useState(null);
  const [tokenType, setTokenType] = useState("pending");
  const [tokenValue, setTokenValue] = useState([]);
  const [skipPatients, setSkipPatients] = useState([]);
  const [finishedPatients, setFinishPatients] = useState([]);
  const [replaceToken, setReplaceToken] = useState(0);
  const [patients, setPatients] = useState([]);
  const [patientIn, setPatientIn] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [feeCount, setFeeCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [onlineTokens, setOnlineTokens] = useState(0);
  const [is_break, setIsBreak] = useState(false);
  const [showBreakModal, setShowBreakModal] = useState(false);
  const [isStartButtonVisible, setStartButtonVisible] = useState(false);
  const [shifts, setShift] = useState([]);
  const [startModel, setStartModel] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [printModal, setPrintModal] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [fees, setFees] = useState(0);
  const [shift_id, setShiftId] = useState(
    localStorage.getItem("shift_id") ?? USER_DETAILS.active_shift_id ?? ""
  );

  const [subscriptionEnd, setSubscriptionEnd] = useState(
    localStorage.getItem("subscription_end") || 30
  );
  const [subscriptionDate, setSubscriptionDate] = useState(
    localStorage.getItem("subscription_date") || ""
  );
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gender: "Male",
    mobile: "",
    email: "",
    shift_id: shift_id,
    checkvaluemsg: "",
  });
  const [permissions, setPermissions] = useState({
    start: true,
    stop: true,
    skip: true,
    recall: true,
    next: true,
    startbreak: true,
    stopbreak: true,
    skipPatient: true,
  });

  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const startOpenModel = (is_active) => {
    setFormData({ ...formData, checkvaluemsg: "" });
    setIsActive(is_active);
    setStartModel(!startModel);
  };

  const handleToggleStartButton = async (e) => {
    e.preventDefault();
    const checkValue = e.target.text_check.value.toLowerCase();
    if (checkValue === "start" || checkValue === "stop") {
      setStartModel(!startModel);
      try {
        if (shift_id.length === 0 && isStartButtonVisible === false) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select your shift.",
          });
          return false;
        }
        setIsLoading(true);
        setFormData({ ...formData, checkvaluemsg: "" });
        const response = await axios.post(
          `${BASE_URL}/doctor/status`,
          { shift_id: formData.shift_id, is_active: isActive },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${AUTH_TOKEN}`,
            },
          }
        );

        if (response.status === 200) {
          allPatient(formData.shift_id);
          setShiftId(formData.shift_id);
          localStorage.setItem("shift_id", formData.shift_id);
          setIsLoading(false);
          setStartButtonVisible(!isStartButtonVisible);
        }
      } catch (error) {
        console.error("Error fetching the API:", error);
        setIsLoading(false);
      }
    } else {
      setFormData({
        ...formData,
        checkvaluemsg: !isStartButtonVisible
          ? "please type start for start."
          : "please type stop for stop.",
      });
      return false;
    }
  };

  //rollback
  const handleRollBackButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/recall`,
        {
          token_number: isRecallToken,
          time: inputValue,
          status: tokenType,
          shift_id: shift_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
        setIsRecallModel(false);
        setInputValue(null);
        setTokenType("panding");
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Skip-rollback

  const handleBreakModel = () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setShowBreakModal(!showBreakModal);
  };

  const allPatient = (shiftId) => {
    axios
      .post(
        `${BASE_URL}/doctor/patient/all`,
        { shift_id: shiftId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.activeShift) {
          localStorage.setItem("shift_id", data.activeShift.id);
          setShiftId(data.activeShift.id);
          setFees(data.activeShift.fees);
          if (data.activeShift.id != shift_id) {
            window.location.reload();
          }
        }
        setFeeCount(data.feeCount);
        setTotalFee(data.totalFee);
        setPatients(data.patients);
        setPatientIn(data.patients.filter(item => item.patient_in === 1).length);
        setSkipPatients(data.skips);
        setFinishPatients(data?.finished);
        setReplaceToken(data?.replaceToken);
        setTokenValue(data.current);
        setStartButtonVisible(data.start);
        setIsBreak(data.is_break);
        setTotal(data.total);
        setOnlineTokens(data.onlineTokens);
        setDescription(data.descriptions);
        setSeconds(data.break_countdown);
        /* setSubscriptionEnd(data.subscription_end);
        setSubscriptionDate(data.subscription_date);
        setIsSubscribe(data.is_subscribe);
        localStorage.setItem("subscription_date", data.subscription_date);
        localStorage.setItem("subscription_end", data.subscription_end);
        if (data.subscription_end == 0) {
          getPermission();
        } */
        setShift(data.shifts);
      })
      .catch((error) => {
        console.error("Error fetching data", error.response.data.message);
        if (error.response.data.message == "Unauthorized") {
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem(AUTH_KEY);
          localStorage.removeItem("role_permissions");
          localStorage.removeItem("menu");
          window.location.href = "../";
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      allPatient(shift_id);
    }, 10000); // Poll every 5 seconds

    return () => clearInterval(interval);
  }, []);

  /*   const echo = new Echo({
    broadcaster: "pusher",
    key: "18149ecf6ecc5e5d658b",
    cluster: "ap2",
    forceTLS: true,
  });

  useEffect(() => {
    echo.channel("data-changed").listen("DataChanged", (event) => {
      console.log("Data updated:", event.data);
      allPatient();
    });

    return () => echo.disconnect();
  }, []); */

  const getPermission = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/permissions`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      const permissions = response.data.data.permissions;
      const role_permissions = response.data.data.role_permissions;
      localStorage.setItem(
        "role_permissions",
        JSON.stringify(role_permissions)
      );
      localStorage.setItem("menu", JSON.stringify(permissions));
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleEmergency = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const data = {
      name: formData.name,
      dob: formData.dob,
      gender: formData.gender,
      mobile: formData.mobile,
      email: formData.email,
      shift_id: shift_id,
    };
    setIsLoading(true);
    try {
      axios
        .post(`${BASE_URL}/doctor/patient/emergency`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        })
        .then((response) => {
          //console.log("Token added successfully", response.data.message);
          Swal.fire({
            icon: "success",
            title: `Token Number: ${response.data.data.token_number}`,
            text: `Time - ${response.data.data.expected_time}`,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error adding token", error.response.data.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to add the token. Please try again.",
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    allPatient(shift_id);
    if (userPer) {
      const startPermission = userPer.some((perm) => perm.id === 8);
      const stopPermission = userPer.some((perm) => perm.id === 9);
      const nextPermission = userPer.some((perm) => perm.id === 10);
      const skipPermission = userPer.some((perm) => perm.id === 11);
      const recallPermission = userPer.some((perm) => perm.id === 15);
      const startbreakPermission = userPer.some((perm) => perm.id === 12);
      const stopbreakPermission = userPer.some((perm) => perm.id === 11);
      const skippataintPermission = userPer.some((perm) => perm.id === 11);
      setPermissions({
        start: startPermission,
        stop: stopPermission,
        next: nextPermission,
        skip: skipPermission,
        recall: recallPermission,
        startbreak: startbreakPermission,
        stopbreak: stopbreakPermission,
        skipPatient: skippataintPermission,
      });
    }
  }, []);

  const modelToggle = () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const type = isModel ? false : true;
    setIsModel(type);
  };

  const recallModelToggle = (value) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsRecallToken(value);
    setIsRecallModel(!isRecallModel);
    setTokenType("recalled");
  };

  const tokenInModelToggle = (patient = null) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    if (patientInModel === true) {
      allPatient(shift_id);
    }
    setPatientDetails(patient);
    setPatientInModel(!patientInModel);
    setIsSkipRecallModel(false);
  };

  const printOpenModel = () => {
    setPrintModal(false);
    setPatientDetails([]);
  };

  const skiprecallModelToggle = (value, type, patient) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const data = isSkipRecallModel ? false : true;
    setPatientDetails(patient);
    setIsRecallToken(value);
    setTokenType(type);
    setIsSkipRecallModel(data);
  };

  const emergencyModelToggle = () => {
    const type = isEmergencyModel ? false : true;
    setIsEmergencyModel(type);
  };

  const handleDescriptionSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/description`,
        { description: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        setInputValue(null);
        modelToggle();
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editToken = (token_id) => {
    navigate(`../admin/token/edit/${token_id}`);
  };

  const shiftChange = (e) => {
    setFormData({
      ...formData,
      ["shift_id"]: e.target.value,
    });
    allPatient(e.target.value);
  };

  return (
    <>
      <div className="dashboard">
        <div
          className="d-md-none"
          style={{
            width: "100%",
            backgroundColor: "black",
            color: "wheat",
            marginBottom: "60px",
          }}
        >
          <Header />
        </div>
        <div className="d-flex">
          <Dashboard />
          <div className="w-100">
            <div className="main-container">
              <hr className="d-block d-lg-none" />
              <Head />
              <div className="w-100">
                <div className="pb-2 px-3">
                  Welcome <b>{USER_DETAILS.name}</b>
                </div>
                {is_break ? (
                  <>
                    <DashboardBreak
                      seconds={seconds}
                      setSeconds={setSeconds}
                      handleBreakModel={handleBreakModel}
                      isLoading={isLoading}
                      permission={permissions.stopbreak}
                      shift_id={shift_id}
                      inputValue={inputValue}
                      allPatient={allPatient}
                      setShowBreakModal={setShowBreakModal}
                      showBreakModal={showBreakModal}
                      setInputValue={setInputValue}
                    />
                    <div className="row justify-content-center mt-2">
                      <div className="col-md-11 text-center">
                        <TokenListDash
                          isLoading={isLoading}
                          patients={patients}
                          editToken={editToken}
                          tokenInModelToggle={tokenInModelToggle}
                        />
                        {skipPatients.length > 0 && (
                          <SkipPatientDash
                            isLoading={isLoading}
                            permissions={permissions}
                            skipPatients={skipPatients}
                            skiprecallModelToggle={skiprecallModelToggle}
                            tokenInModelToggle={tokenInModelToggle}
                            editToken={editToken}
                          />
                        )}
                        {finishedPatients.length > 0 && (
                          <DonePatientList
                            finishedPatients={finishedPatients}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="container">
                    {/* {subscriptionEnd <= 8 && (
                      <div className="alert alert-danger d-flex flex-wrap">
                        {subscriptionEnd <= 8 && subscriptionEnd >= 1 ? (
                          <h6 className="text-danger mb-0">
                            Your {!isSubscribe && "demo"} subscription will
                            expire on {subscriptionDate}.
                          </h6>
                        ) : (
                          <h6 className="text-danger mb-0">
                            Your {!isSubscribe && "demo"} subscription has
                            expired on {subscriptionDate}.{" "}
                            {!isSubscribe &&
                              "& You don't have subscription. Please subscribe to continue."}
                          </h6>
                        )}
                        <button
                          className="btn btn-primary py-0 ms-lg-2 mb-0"
                          onClick={() => navigate(`/admin/wallet`)}
                        >
                          Renew it
                        </button>
                      </div>
                    )} */}
                    <div className="mb-0">
                      <DashboardTotal
                        total={total}
                        pending={patients.length}
                        skip={skipPatients.length}
                        done={finishedPatients.length}
                        fees={totalFee}
                        feeCount={feeCount}
                        online={onlineTokens}
                        replace={replaceToken}
                        patientIn={patientIn}
                      />
                    </div>
                    <div>
                      <div className="row">
                        {shifts.length > 1 && (
                          <div className="col-md-3 mb-2">
                            <ShiftComponent
                              shift_id={formData.shift_id}
                              shiftChange={shiftChange}
                              shifts={shifts}
                              status={isStartButtonVisible}
                            />
                          </div>
                        )}
                        <div className="col-md-3 mb-2">
                          <StartStop
                            isStartButtonVisible={isStartButtonVisible}
                            permissions={permissions}
                            startOpenModel={startOpenModel}
                            isLoading={isLoading}
                          />
                        </div>
                        {!isStartButtonVisible && (
                          <div className="col mb-2">
                            <TokenListDash
                              isLoading={isLoading}
                              patients={patients}
                              editToken={editToken}
                              tokenInModelToggle={tokenInModelToggle}
                            />
                            {finishedPatients.length > 0 && (
                              <DonePatientList
                                finishedPatients={finishedPatients}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {isStartButtonVisible && (
                      <div className="row">
                        <div className="col-md-12 col-lg-7 col-sm-12">
                          <div className="card mb-4">
                            <div
                              className="card-header"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Current Token
                              </h5>
                            </div>
                            <div
                              className="card-body "
                              style={{ backgroundColor: "#E0EEEE" }}
                            >
                              {/* =================================== desktop View ================================= */}

                              <div className="">
                                <div className="">
                                  <div className="token-button d-flex justify-content-between align-items-center">
                                    {permissions.startbreak > 0 && (
                                      <button
                                        className="btn btn-danger"
                                        type="button"
                                        onClick={handleBreakModel}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span className="">
                                          <MdOutlineNotes /> Start Break
                                        </span>
                                      </button>
                                    )}
                                    {!is_break && (
                                      <>
                                        {permissions.recall > 0 && (
                                          <RecallButton
                                            modelToggle={recallModelToggle}
                                            isLoading={isLoading}
                                            token_number={
                                              tokenValue?.token_number
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="my-3 d-flex align-items-center justify-content-between">
                                    <div
                                      className="mx-auto"
                                      style={{ width: "300px" }}
                                    >
                                      <ViewToken
                                        is_break={is_break}
                                        tokenValue={tokenValue}
                                      />
                                    </div>
                                  </div>
                                  {!is_break && (
                                    <div className="d-flex justify-content-between align-items-center">
                                      {permissions.skip > 0 && (
                                        <SkipButton
                                          allPatient={allPatient}
                                          shift_id={shift_id}
                                          setIsRecallToken={setIsRecallToken}
                                          setIsRecallModel={setIsRecallModel}
                                          setTokenType={setTokenType}
                                          isRecallToken={isRecallToken}
                                          tokenType={tokenType}
                                          title="Skip"
                                        />
                                      )}
                                      {permissions.next > 0 && (
                                        <NextButton
                                          allPatient={allPatient}
                                          shift_id={shift_id}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* =================================== end desktop View ================================= */}
                            </div>
                          </div>
                          <DescList
                            descriptions={descriptions}
                            modelToggle={modelToggle}
                          />
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                          <PatientList
                            patients={patients}
                            skiprecallModelToggle={skiprecallModelToggle}
                            editToken={editToken}
                            tokenInModelToggle={tokenInModelToggle}
                            isLoading={isLoading}
                            fees={fees}
                          />
                          {skipPatients.length > 0 && (
                            <SkipPatientDash
                              isLoading={isLoading}
                              permissions={permissions}
                              skipPatients={skipPatients}
                              skiprecallModelToggle={skiprecallModelToggle}
                              tokenInModelToggle={tokenInModelToggle}
                              editToken={editToken}
                            />
                          )}
                          {finishedPatients.length > 0 && (
                            <DonePatientList
                              finishedPatients={finishedPatients}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModel === true && (
        <DescriptionModel
          show={isModel}
          handleSubmit={handleDescriptionSubmit}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={modelToggle}
          isLoading={isLoading}
        />
      )}
      {isRecallModel === true && (
        <RecallModel
          show={isRecallModel}
          handleSubmit={handleRollBackButtonClick}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={recallModelToggle}
          isLoading={isLoading}
        />
      )}
      {patientInModel === true && (
        <PatientInModel
          show={patientInModel}
          patient={patientDetails}
          setPrintModal={setPrintModal}
          setPatientInModel={setPatientInModel}
          isLoading={isLoading}
          allPatient={allPatient}
          shift_id={formData.shift_id}
          setPatientDetails={setPatientDetails}
        />
      )}
      {showBreakModal === true && (
        <BreakModel
          show={showBreakModal}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={handleBreakModel}
          setShowBreakModal={setShowBreakModal}
          shift_id={shift_id}
          allPatient={allPatient}
        />
      )}
      {isSkipRecallModel === true && (
        <SkipRecallModel
          show={isSkipRecallModel}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={skiprecallModelToggle}
          tokenType={tokenType}
          tokenInModelToggle={tokenInModelToggle}
          patient={patientDetails}
          allPatient={allPatient}
          setIsRecallModel={setIsRecallModel}
          setTokenType={setTokenType}
          shift_id={shift_id}
          setIsRecallToken={setIsRecallToken}
          isRecallToken={isRecallToken}
          setIsSkipRecallModel={setIsSkipRecallModel}
        />
      )}
      {isEmergencyModel === true && (
        <EmergencyModel
          show={isEmergencyModel}
          handleSubmit={handleEmergency}
          formData={formData}
          handleInputChange={handleInputChange}
          modelToggle={emergencyModelToggle}
          isLoading={isLoading}
        />
      )}
      {startModel === true && (
        <StartModel
          show={startModel}
          handleSubmit={handleToggleStartButton}
          modelToggle={startOpenModel}
          isStartButtonVisible={isStartButtonVisible}
          isLoading={isLoading}
          formData={formData}
        />
      )}

      {printModal === true && (
        <PrintModel
          show={printModal}
          modelToggle={printOpenModel}
          patient={patientDetails}
        />
      )}
    </>
  );
};
