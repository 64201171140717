import { useNavigate } from "react-router-dom";
import { Token } from "../Token";

export const PatientList = ({
  patients,
  skiprecallModelToggle,
  editToken,
  tokenInModelToggle,
  isLoading,
  fees
}) => {
  const navigate = useNavigate();
  return (
    <div className="card mb-4">
      <div
        className="card-header d-flex justify-content-between"
        style={{ backgroundColor: "#96C8A2" }}
      >
        <h6 className="fw-bold text-center">Patients List</h6>
        <button onClick={() => navigate(`../admin/token`)} className="btn btn-info py-0">Add Patient</button>
      </div>
      <div
        className="card-body pe-0 ps-0"
        style={{ backgroundColor: "#E0EEEE" }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="w-100" style={{ height: "250px", overflowY: "auto" }}>
            <div className="d-flex flex-wrap ps-2">
              {patients.map((patient, index) => (
                <Token
                  key={index}
                  fees={fees}
                  patient={patient}
                  recallModelToggle={skiprecallModelToggle}
                  editToken={editToken}
                  tokenInModelToggle={tokenInModelToggle}
                  type="pending"
                  status="emergency"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
