import React from "react";
import { BiEditAlt, BiUpArrowCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const TokenListDash = ({
  isLoading,
  patients,
  editToken,
  tokenInModelToggle,
}) => {
  const navigate = useNavigate();
  return (
    <div className="card mb-4">
      <div
        className="card-header d-flex justify-content-between"
        style={{ backgroundColor: "#96C8A2" }}
      >
        <h5 className="fw-bold text-center">Patients List</h5>
        <button onClick={() => navigate(`../admin/token`)} className="btn btn-info py-0">Add Patient</button>
      </div>
      <div
        className="card-body pe-0 ps-0"
        style={{ backgroundColor: "#E0EEEE" }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div
            className="w-100"
            style={{
              height: "200px",
              overflowY: "auto",
            }}
          >
            <div className="d-flex flex-wrap ps-2">
              {patients.map((patient) => (
                <div
                  className="d-flex text-center text-white px-2 py-1 mb-2 me-1"
                  style={{
                    backgroundColor:
                      patient.patient_in == 1
                        ? patient.is_paid == ""
                          ? "#bec719"
                          : "#00a19a"
                        : "#7bada8",
                    borderRadius: "5px",
                    border:
                      patient.is_online == 1 && patient.is_place_date == "yes"
                        ? "2px solid green"
                        : patient.is_online == 1
                        ? "2px solid black"
                        : patient.is_place_date == "yes"
                        ? "2px solid blue"
                        : "",
                  }}
                >
                  {patient.token_number} -{patient?.patient?.name || "MKITE"}
                  {patient.is_online == 0 && (
                    <span
                      role="button"
                      onClick={() => editToken(patient.id)}
                      className="ms-2"
                      style={{
                        borderLeft: "1px solid white",
                      }}
                      title="Edit Patient"
                    >
                      <BiEditAlt style={{ color: "#ff0000" }} />
                    </span>
                  )}
                  {patient.patient_in != 1 && patient.patient && (
                    <span
                      role="button"
                      onClick={() => tokenInModelToggle(patient)}
                      className="ms-2"
                      style={{
                        borderLeft: "1px solid white",
                      }}
                      title="Patient In"
                    >
                      {" "}
                      <BiUpArrowCircle style={{ color: "yellow" }} />
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenListDash;
