import React, { useState } from "react";
import { AiOutlineStop } from "react-icons/ai";
import { VscDebugStart } from "react-icons/vsc";

export const StartStop = ({
  isStartButtonVisible,
  permissions,
  startOpenModel,
  isLoading,
}) => {
  return (
    <>
      {isStartButtonVisible ? (
        permissions.start > 0 && (
          <button
            className="btn btn-danger px-4 mb-3 form-control"
            type="button"
            onClick={() => startOpenModel(0)}
            disabled={isLoading}
          >
            <span className="fs-5 fw-bold align-items-center">
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              <AiOutlineStop className="" /> Stop
            </span>
          </button>
        )
      ) : (
        <>
          {permissions.stop > 0 && (
            <button
              className="btn btn-success px-4 mb-3 form-control"
              type="button"
              onClick={() => startOpenModel(1)}
              disabled={isLoading}
            >
              <span className="fs-5 fw-bold align-items-center">
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                <VscDebugStart className="" /> Start
              </span>
            </button>
          )}
        </>
      )}
    </>
  );
};
