import React from "react";
import { IoMdRefresh } from "react-icons/io";

const RecallButton = ({ modelToggle, isLoading, token_number }) => {
  return (
    <button
      className="btn btn-info"
      type="button"
      onClick={() => modelToggle(token_number)}
      disabled={isLoading}
      style={{ width: "160px" }}
    >
      <span>
        {isLoading && (
          <span className="spinner-border spinner-border-sm me-1"></span>
        )}
        <IoMdRefresh />
        Recall
      </span>
    </button>
  );
};

export default RecallButton;
