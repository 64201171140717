import React, { useState } from "react";
import { BiSkipNext } from "react-icons/bi";
import { AUTH_TOKEN, BASE_URL } from "../../../utils";
import axios from "axios";
import Swal from "sweetalert2";

export const NextButton = ({ allPatient, shift_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  //next
  const handleNextButtonClick = async () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return;
    }

    setIsLoading(true);

    try {
      // Make the API call
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/next`,
        { shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
      } else {
        Swal.fire({
          icon: "error",
          title: "API Error",
          text: "Failed to fetch the next patient.",
        });
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "An error occurred while making the request. Please try again later.",
      });
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className="btn btn-primary mt-2"
      type="button"
      onClick={() => handleNextButtonClick()}
      disabled={isLoading}
      style={{ width: "160px" }}
    >
      <span className="">
        {isLoading && (
          <span className="spinner-border spinner-border-sm mr-1"></span>
        )}
        <BiSkipNext /> Next
      </span>
    </button>
  );
};
