import React, { useState } from "react";
import { BiEditAlt, BiRupee, BiUpArrowCircle } from "react-icons/bi";
import FeeModel from "./components/FeeModel";
export const Token = ({
  patient,
  recallModelToggle,
  type,
  editToken,
  tokenInModelToggle,
  fees = 0,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div
      className="text-center text-white px-2 py-1 mb-2 me-1"
      style={{
        backgroundColor:
          patient.patient_in == 1
            ? patient.is_paid == ""
              ? "#bec719"
              : "#00a19a"
            : "#7bada8",
        borderRadius: "5px",
        fontWeight:600,
        border:
          patient.is_online == 1 && patient.is_place_date == "yes"
            ? "2px solid green"
            : patient.is_online == 1
            ? "2px solid black"
            : patient.is_place_date == "yes"
            ? "2px solid blue"
            : "",
      }}
      role="button"
    >
      <span
        onClick={
          patient.patient || patient.type == "Skip"
            ? () => recallModelToggle(patient.token_number, type, patient)
            : undefined
        }
      >
        {patient.token_number} - {patient?.patient?.name || "MKITE"}
      </span>
      {patient.is_online == 0 && (
        <span
          onClick={() => editToken(patient.id)}
          className="ms-2 text-warning"
          style={{ borderLeft: "1px solid white" }}
          title="Edit Patient"
          role="button"
        >
          <BiEditAlt style={{ color: "#ff0000" }} />
        </span>
      )}
      {patient.patient_in != 1 && patient.patient && (
        <span
          onClick={() => tokenInModelToggle(patient)}
          className="ms-2"
          style={{ borderLeft: "1px solid white" }}
          title="Patient In"
          role="button"
        >
          {" "}
          <BiUpArrowCircle style={{ color: "yellow" }} />
        </span>
      )}
      {patient.is_paid != "Paid" && patient.patient && (
        <span
          onClick={() => handleCloseModal()}
          className="ms-2"
          style={{ borderLeft: "1px solid white" }}
          title="Fees"
          role="button"
        >
          {" "}
          <BiRupee style={{ color: "green" }} />
        </span>
      )}
      {fees != 0 && (
        <FeeModel
          show={showModal}
          handleClose={handleCloseModal}
          tokenid={patient.id}
          fees={fees}
        />
      )}
    </div>
  );
};
