import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AUTH_TOKEN, BASE_URL } from "../utils";
import Swal from "sweetalert2";
import axios from "axios";

export const BreakModel = ({
  show,
  inputValue,
  setInputValue,
  modelToggle,
  setShowBreakModal,
  shift_id,
  allPatient
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const breakUrl =
      inputValue != null
        ? `${BASE_URL}/doctor/start-break`
        : `${BASE_URL}/doctor/end-break`;

    setIsLoading(true);
    try {
      const response = await axios.post(
        breakUrl,
        { break_duration: inputValue, shift_id: shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        if (inputValue) {
          setShowBreakModal(!show);
        }
        setInputValue(null);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="h6">Add Break Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="">
            <input
              type="number"
              className="form-control"
              placeholder="Enter Minuts"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="mt-3 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
