import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoMdRefresh } from "react-icons/io";
import { SkipButton } from "../views/admin/Buttons/SkipButton";
import { useState } from "react";
import { AUTH_TOKEN, BASE_URL } from "../utils";
import axios from "axios";
import Swal from "sweetalert2";

export const SkipRecallModel = ({
  show,
  inputValue,
  setInputValue,
  modelToggle,
  tokenType,
  tokenInModelToggle,
  patient,
  allPatient,
  setIsRecallModel,
  setTokenType,
  shift_id,
  setIsRecallToken,
  isRecallToken,
  setIsSkipRecallModel,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/skip-recall`,
        {
          token_number: isRecallToken,
          skip_number: inputValue,
          status: tokenType,
          shift_id: shift_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setInputValue(null);
        allPatient(shift_id);
        setTokenType("panding");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsRecallToken(null);
      setIsSkipRecallModel(false);
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Body>
        {tokenType == "pending" ? (
          <div className="d-flex justify-content-between">
            <h6 className="h6">Recall Token</h6>
            <button
              className="btn btn-info btn-sm"
              type="button"
              onClick={() => tokenInModelToggle(patient)}
              disabled={isLoading}
            >
              <span className="">
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                <IoMdRefresh />
                Patient In
              </span>
            </button>
          </div>
        ) : (
          <h6 className="h6">Skip Recall Number</h6>
        )}
        <hr />
        <form onSubmit={handleSubmit}>
          <div className="">
            <input
              type="number"
              className="form-control"
              placeholder="Enter Count Number"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className="mt-3 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>

        {tokenType == "pending" && (
          <>
            <hr />
            <div
              className="d-flex justify-content-around"
              onClick={modelToggle}
            >
              <h5 className="mt-1">You want to skip the token directly.</h5>
              <SkipButton
                setIsRecallToken={setIsRecallToken}
                allPatient={allPatient}
                setIsRecallModel={setIsRecallModel}
                setTokenType={setTokenType}
                isRecallToken={isRecallToken}
                tokenType={tokenType}
                shift_id={shift_id}
                title="Skip Token"
              />
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
