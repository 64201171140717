import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import Head from "./components/Head";
import authService from "../../services/authService";

export const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [specialities, setSpecialities] = useState([""]);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    about: "",
    profile: "",
    image: "",
    category_id: "",
    ofc_contact_no_1: "",
    ofc_contact_no_2: "",
    registration_hospital_name:"",
  });

  const getProfile = async () => {
    const reqeustParam = {};
    const resp = authService.getProfile(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          const array = {
            name: data.name,
            mobile: data.mobile,
            about: data.location.about,
            profile: data.profile,
            image: "",
            category_id: data?.location?.category_id,
            ofc_contact_no_1: data?.location?.ofc_contact_no_1,
            ofc_contact_no_2: data?.location?.ofc_contact_no_2,
            registration_hospital_name:data?.location?.registration_hospital_name
          };
          data.specialists.length > 0 && setSpecialities(data.specialists);
          setFormData(array);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const getCategory = async () => {
    const reqeustParam = { is_active: 1 };
    const resp = authService.categories(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setCategories(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name === "image") {
      value = e.target.files[0];
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const SpecialityChange = (index, event) => {
    const newFields = [...specialities];
    newFields[index] = event.target.value;
    setSpecialities(newFields);
  };

  const handleAddField = () => {
    setSpecialities([...specialities, ""]);
  };

  const handleRemoveField = (index) => {
    const data = specialities.filter((_, i) => i !== index);
    setSpecialities(data);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("mobile", formData.mobile);
    data.append("about", formData.about);
    data.append("category_id", formData.category_id);
    data.append("ofc_contact_no_1", formData.ofc_contact_no_1);
    data.append("ofc_contact_no_2", formData.ofc_contact_no_2);
    data.append("registration_hospital_name", formData.registration_hospital_name);
    data.append("image", formData.image);
    data.append("specialists", specialities);
    const resp = authService.updateProfile(data);
    resp
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          getProfile();
          Swal.fire({
            icon: "success",
            title: `Profile update successfully`,
            text: `Your profile has been successfully updated.`,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCategory();
    getProfile();
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "80px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <form className="" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-2 mb-4">
                  <img
                    src={formData.profile || "../doct.png"}
                    alt="doctor profile"
                    className="ps-2 mb-3"
                    style={{
                      width: "100%",
                      borderRadius: "10%",
                    }}
                  />
                  <input
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    className="form-control bg-light text-dark"
                  />
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="">Hospital Name</label>
                      <input
                        type="text"
                        name="registration_hospital_name"
                        value={formData.registration_hospital_name}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="">Dr. Mobile No.</label>
                      <input
                        type="text"
                        name="mobile"
                        inputmode="numeric"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark"
                        required
                        maxLength={10}
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="">Category</label>
                      <select
                        className="form-control"
                        onChange={handleInputChange}
                        value={formData.category_id}
                        name="category_id"
                      >
                        <option value="">Select Category</option>
                        {categories &&
                          categories.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label className="">About</label>
                      <textarea
                        name="about"
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark"
                        required
                        value={formData.about}
                        style={{ height: "150px" }}
                      ></textarea>
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="">Clinic Mobile No.</label>
                      <input
                        type="text"
                        name="ofc_contact_no_1"
                        value={formData.ofc_contact_no_1}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark"
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="">Staff Mobile No.</label>
                      <input
                        type="text"
                        name="ofc_contact_no_2"
                        value={formData.ofc_contact_no_2}
                        onChange={handleInputChange}
                        className="form-control bg-light text-dark"
                        required
                      />
                    </div>
                    <div className="py-3">
                      <h4>Specialities</h4>
                      <hr />
                      <div className="row">
                        {specialities.map((field, index) => (
                          <div className="col-md-6 mb-3 d-flex">
                            <input
                              type="text"
                              value={field}
                              onChange={(e) => SpecialityChange(index, e)}
                              className="form-control bg-light text-dark me-2"
                              required
                            />
                            <button
                              type="button"
                              className={`btn ${
                                index == 0 ? "btn-success" : "btn-danger"
                              }`}
                              disabled={loading}
                              onClick={() =>
                                index == 0
                                  ? handleAddField()
                                  : handleRemoveField(index)
                              }
                            >
                              {index == 0 ? "+" : "-"}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success mt-3 px-5"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
