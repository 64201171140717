import React from "react";

export const DashboardTotal = ({
  total,
  pending,
  skip,
  fees,
  feeCount,
  online,
  replace,
  patientIn,
  done
}) => {
  return (
    <div className="row">
      <div className="col-6 col-lg-2">
        <div className="alert alert-success text-center py-1 px-1">
          <p className="h2 py-0 my-0">{total}</p>
          <p className="py-0 my-0">Total Patient</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div className="alert alert-primary text-center py-1 px-1">
          <p className="h2 py-0 my-0">{pending}/{skip}</p>
          <p className="py-0 my-0">Pending/Skip</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div className="alert alert-warning text-center py-1 px-1">
          <p className="h2 py-0 my-0">{done}</p>
          <p className="py-0 my-0">done Patient</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div className="alert alert-danger text-center py-1 px-1">
          <p className="h2 py-0 my-0">
            {fees}/{feeCount}
          </p>
          <p className="py-0 my-0">Total Fees</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div
          className="alert alert-success text-center py-1 px-1"
          style={{ background: "#cdc4e7" }}
        >
          <p className="h2 py-0 my-0">
            {online}/{replace}
          </p>
          <p className="py-0 my-0">Online/Replace</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div
          className="alert alert-info text-center py-1 px-1"
          style={{ background: "#62ed7366" }}
        >
          <p className="h2 py-0 my-0">{patientIn}</p>
          <p className="py-0 my-0">Patient In</p>
        </div>
      </div>
    </div>
  );
};
