import React from "react";

export const Work = () => {
  return (
    <div className="mt-5">
      <div className="container-fluid">
        <h2 className="text-website text-center">How it Works</h2>
        <section className="section_padding partnerSec1 mt-5">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-start">
              {/* Doctor Section */}
              <div className="col-md-6 mb-4">
                <div className="whatWeDo bg-light p-4">
                  <h2 className="text-center text-info mb-4">For Doctors</h2>
                  <ul className="feature-list">
                    <li>
                      <strong>Manage appointments:</strong> Schedule
                      appointments, view patient details, and track appointment
                      history.
                    </li>
                    <li>
                      <strong>Generate virtual tokens:</strong> Allow patients
                      to join a queue online, eliminating the need for physical
                      tokens.
                    </li>
                    <li>
                      <strong>Receive real-time updates:</strong> Monitor
                      patient arrival and update estimated wait times.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Patient Section */}
              <div className="col-md-6 mb-4">
                <div className="whatWeDo bg-light p-4">
                  <h2 className="text-center text-info mb-4">For Patients</h2>
                  <ul className="feature-list">
                    <li>
                      <strong>Book appointments:</strong> Schedule appointments
                      online at your convenience.
                    </li>
                    <li>
                      <strong>Join the virtual queue:</strong> Obtain a virtual
                      token number and receive estimated wait times.
                    </li>
                    <li>
                      <strong>Stay informed:</strong> Receive notifications with
                      updates on your appointment status.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section_padding partnerSec1 mt-4">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-4 col-lg-4 ">
                <div className="about_us_img">
                  <img
                    src={selectdoctororclinic}
                    width="100%"
                    height="auto"
                    className="rounded-circle img-hover-effect"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-8 col-lg-7 col-lg-6">
                <div className="whatWeDo">
                  <h1 className="text-start text-website">
                    Enter Mobile Number:
                  </h1>
                  <p
                    className="card-paragraph mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    Go to appointment time tracker and enter the mobile number you have provided to Doctor at the time of booking appointment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_padding partnerSec1">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-8 col-lg-7 col-lg-6 ">
                <div className="whatWeDo">
                  <h1 className="text-start text-website">
                    Enter your token number:
                  </h1>
                  <p
                    className="card-paragraph mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    Then enter the appointment token or booking number provided
                    by the clinic or hospital.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 mb-5">
                <div className="about_us_img">
                  <img
                    src={entertoken}
                    width="100%"
                    height="auto"
                    className="rounded-circle img-hover-effect"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_padding partnerSec1 ">
          <div className="container ">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-4 col-lg-4 ">
                <div className="about_us_img">
                  <img
                    src={submit}
                    width="100%"
                    height="auto"
                    className="rounded-circle img-hover-effect"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-8 col-lg-7 col-lg-6 mb-5">
                <div className="whatWeDo">
                  <h1 className="text-start text-website">Submit and See:</h1>
                  <p
                    className="card-paragraph mt-4"
                    style={{ textAlign: "justify" }}
                  >
                    After that click on submit button and wait for a moment ,Now
                    know your estimated time of appointment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center">
          <p className="text-center">
            <strong> Note:</strong> If you want to know the current scenario of
            Clinic/Hospital in details with more features then signup or login
            and access the full window of appointment token running system.
          </p>
          <Button variant="info"> Get Started</Button>
        </div> */}
      </div>
    </div>
  );
};
