import { AiOutlinePlusCircle } from "react-icons/ai";

export const DescList = ({descriptions, modelToggle}) => {
  return (
    <div className="card mb-4">
      <div
        className="card-header d-flex justify-content-between"
        style={{ backgroundColor: "#96C8A2" }}
      >
        <h5 className="fw-bold text-center">Description</h5>
        <AiOutlinePlusCircle
          className="fw-bold fs-3"
          onClick={modelToggle}
          role="button"
        />
      </div>
      <div className="card-body">
        <div
          className="card-title text-center"
          style={{ backgroundColor: "#96C8A2" }}
        ></div>
        {descriptions.map((item, index) => (
          <p key={index}>
            {index + 1} - {item.message}
          </p>
        ))}
      </div>
    </div>
  );
};
