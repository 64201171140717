export const ViewToken = ({ is_break, tokenValue }) => {
  return (
    <div
      className="text-center pb-3 px-3"
      style={{
        backgroundColor: "grey",
        borderRadius: "50px",
      }}
    >
      {!is_break ? (
        <div>
          <span className="text-center" style={{ fontSize: "2.5rem" }}>
            {tokenValue?.token_number}
          </span>
          <h4 className="fs-lg-4 h4 py-0 my-0">
            {tokenValue?.patient?.name || "No Name"}
          </h4>
          <small>{tokenValue?.patient?.email}</small>
        </div>
      ) : (
        <span style={{ fontSize: "2rem" }}>It's Break</span>
      )}
    </div>
  );
};
