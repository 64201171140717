import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import logo from "../image/logo.png";
export const Header = ({ isLogin }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar expand="lg" className="">
        <Container>
          <Link
            className="text-decoration-none text-dark ms-3 text-website"
            to="/"
          >
            <img src={logo} />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto m-2 my-lg-0"
              style={{ maxHeight: "200px" }}
              navbarScroll
            >
              <Link
                className="text-decoration-none ms-4 active"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="/"
              >
                Home
              </Link>
              <Link
                className="text-decoration-none ms-4"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="/work"
              >
                How it Works
              </Link>
              {/* <Link
                className="text-decoration-none ms-4"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="/articles"
              >
                Articles
              </Link> */}
              <Link
                className="text-decoration-none ms-4"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="/associatedoctor"
              >
                Doctors
              </Link>
              <Link
                className="text-decoration-none ms-4"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="/aboutus"
              >
                About Us{" "}
              </Link>
              <Link
                className="text-decoration-none ms-4"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="/contact"
              >
                Contact Us
              </Link>

              {/* <Link
                className="text-decoration-none ms-4"
                style={{ fontWeight: 600, color: "#00a19a" }}
                to="review-us"
              >
                Review Us
              </Link> */}
            </Nav>
            {isLogin ? (
              <Button
                className="ms-auto text-white"
                variant="info"
                style={{ background: "#00a19a", fontWeight: 600 }}
                onClick={() => navigate("admin/dashboard")}
              >
                Dashboard
              </Button>
            ) : (
              <Button
                className="ms-auto text-white px-4"
                variant="info"
                style={{ background: "#00a19a", fontWeight: 600 }}
                onClick={() => navigate("login")}
              >
                LogIn
              </Button>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
