import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";
import { Divider } from "antd";
import Utils from "../../../utils/Utils";
import { BiMinus, BiPlus } from "react-icons/bi";
export const ShiftModel = ({
  show,
  handleSubmit,
  handleInputChange,
  formData,
  modelToggle,
  isLoading,
  mode,
}) => {
  const initialWeekDays = [
    { key: "Mon", title: "Monday", isChecked: false },
    { key: "Tue", title: "Tuesday", isChecked: false },
    { key: "Wed", title: "Wednesday", isChecked: false },
    { key: "Thu", title: "Thursday", isChecked: false },
    { key: "Fri", title: "Friday", isChecked: false },
    { key: "Sat", title: "Saturday", isChecked: false },
    { key: "Sun", title: "Sunday", isChecked: false },
  ];
  const [multipleDate, setMultipleDate] = useState([]);
  const [today, setToday] = useState(null);

  const addDates = () => {
    setMultipleDate([...multipleDate, today]);
  };

  const minusDates = (index) => {
    const data = multipleDate.filter((_, i) => i !== index);
    setMultipleDate(data);
  };

  const [weekDays, setWeekDays] = useState(
    initialWeekDays.map((day) => ({
      ...day,
      isChecked: formData.weekdays?.includes(day.key),
    }))
  );

  const handleCheckboxChange = (key) => {
    setWeekDays((prevWeekDays) =>
      prevWeekDays.map((day) =>
        day.key === key ? { ...day, isChecked: !day.isChecked } : day
      )
    );
  };

  useEffect(() => {
    var todate = Utils.todayDate();
    setToday(todate);
    if (formData.offDates && formData.offDates.length > 0) {
      setMultipleDate(formData.offDates);
    } else {
      setMultipleDate([]);
    }
  }, []);
  const setWeeks = weekDays.map((item) => (
    <div className="col-md-3 mb-3">
      <div className="d-flex" key={`day_${item.key}`}>
        <input
          type="checkbox"
          name="days"
          value={item.key}
          checked={item.isChecked}
          style={{ width: "25px", height: "25px" }}
          onChange={() => handleCheckboxChange(item.key)}
        />
        <h6 className="ps-2">{item.title}</h6>
      </div>
    </div>
  ));
  return (
    <div>
      <Modal className="modal-lg" show={show}>
        <Modal.Header>
          <Modal.Title className="h6">{mode} Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Shift Name</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="name"
                  type="text"
                  value={formData.name}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Start Time</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="from"
                  type="time"
                  value={formData.from}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">End Time</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="end_time"
                  type="time"
                  value={formData.end_time}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Consultation Time (in Min)</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="avg_consult_time"
                  type="number"
                  value={formData.avg_consult_time}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Maximum Patient</label>
                <input
                  className="form-control"
                  placeholder="in number"
                  onChange={(e) => handleInputChange(e)}
                  name="max_patient"
                  type="number"
                  value={formData.max_patient}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label className="mb-1">Replace Token Amount</label>
                <input
                  className="form-control"
                  placeholder="in number"
                  onChange={(e) => handleInputChange(e)}
                  name="replace_amount"
                  type="number"
                  value={formData.replace_amount}
                />
              </div>
              <h6>Doctor Setup</h6>
              <hr />
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Hospital Name</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="hospital_name"
                  type="text"
                  value={formData.hospital_name}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Doctor Name</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="doctor_name"
                  type="text"
                  value={formData.doctor_name}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Doctor fees</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="fees"
                  type="text"
                  value={formData.fees}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Address</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="address"
                  type="text"
                  value={formData.address}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <label className="mb-1">Map Location Link</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="map_location"
                  type="text"
                  value={formData.map_location}
                />
              </div>

              <h6>Online Booking Setup</h6>
              <hr />
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">
                  Online booking start token number
                </label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="affter_patient"
                  type="number"
                  value={formData.affter_patient}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Online Booking Status</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="is_booking"
                  type="text"
                  value={formData.is_booking}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Online booking End Time</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="booking_time"
                  type="time"
                  value={formData.booking_time}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Advance Booking Day</label>
                <input
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="booking_days"
                  type="number"
                  placeholder="3"
                  value={formData.booking_days}
                />
              </div>
              <div className="col-md-12">
                <h6>Week off Days</h6>
                <Divider className="my-0 py-0" />
                <div className="my-3 row">{setWeeks}</div>
              </div>
              <h6>
                Set Off Date{" "}
                <button
                  type="button"
                  className="btn btn-warning ms-1 py-0"
                  onClick={() => addDates()}
                >
                  <BiPlus /> add more date
                </button>
              </h6>
              <Divider className="my-2 mt-1 py-0" />
              <div className="row">
                {multipleDate.length > 0 &&
                  multipleDate.map((item, index) => (
                    <div
                      className="col-md-4 mb-3 d-flex"
                      key={`_dates${index}`}
                    >
                      <div>
                        <input
                          type="date"
                          name="offDates"
                          defaultValue={item}
                          className="form-control"
                          pattern="dd-mm-yyyy"
                          min={today}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger ms-1"
                        onClick={() => minusDates(index)}
                        style={{ width: "40px" }}
                      >
                        <BiMinus />
                      </button>
                    </div>
                  ))}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">Token Auto Reserve</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="auto_reserve"
                  value={formData.auto_reserve}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="mb-1">
                  Generate Auto Reserve Token After Patient
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Token count"
                  onChange={(e) => handleInputChange(e)}
                  name="ar_after_token_count"
                  inputmode="numeric"
                  maxLength={1}
                  minLength={1}
                  onKeyPress={(e) => !/[1-9]/.test(e.key) && e.preventDefault()}
                  value={formData.ar_after_token_count}
                  required
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-end">
              <Button variant="secondary" onClick={modelToggle}>
                Close
              </Button>
              <button
                disabled={isLoading}
                className="btn btn-primary ms-2"
                type="submit"
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
